import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from './Trend.module.css';
import { fetchTrendtocks, selectTrendStocks } from "./TrendSlice";
import { Table, Pagination } from "rsuite";
import { selectUserWatchlist, selectIndices } from "../watchlist/WatchlistSlice";
import "rsuite/dist/rsuite.min.css";
import "../../util/css/Table.css";
import kotakImage from '../../assets/image/dashboard-images/kotak_logo.png'
import upTrendIcon from '../../assets/image/dashboard-images/uptrend-icon2.png'
import downTrendIcon from '../../assets/image/dashboard-images/down-arrow 1.png'
import downArrow from '../../assets/image/dashboard-images/image 8.png'
import { FiChevronsRight } from "react-icons/fi";
import { FiChevronsLeft } from "react-icons/fi";

export const Trend = () => {

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [trendType, setTrendType] = useState('D');

  const { Column, HeaderCell, Cell } = Table;
  const dispatch = useDispatch();
  const trend_stocks = useSelector(selectTrendStocks);
  const watchlists = useSelector(selectUserWatchlist) || [];
  const indices = useSelector(selectIndices) || [];
  const [watchlistSelected, setWatchlistSelected] = useState('');
  const [indexSelected, setIndexSelected] = useState('');

  const [trendCardsPerPage, setTrendCardsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);



  useEffect(() => {
    setIndexSelected(indices[0].indexUId);
    const req = {
      indexUId: indices[0].indexUId
    };
    dispatch(fetchTrendtocks(req));
  }, [dispatch]);

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const typeMap = {
    'D': { key: 'day', trendKey: 'day.trend', percentagekey: 'day.percentage_diff', supportKey: 'day.support', resistanceKey: 'day.resistance' },
    'W': { key: 'week', trendKey: 'week.trend', percentagekey: 'week.percentage_diff', supportKey: 'week.support', resistanceKey: 'week.resistance' },
    'M': { key: 'month', trendKey: 'month.trend', percentagekey: 'month.percentage_diff', supportKey: 'month.support', resistanceKey: 'month.resistance' },
  }

  const data = trend_stocks.filter(t => t[typeMap[trendType].key].trend !== "NA").filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  // const data1 = trend_stocks.filter(t => t[typeMap[trendType].key].trend !== "NA")
  // console.log(data1);

  const getTrendType = () => {
    return typeMap[trendType].trendKey;
  }
  const getPercentageDiff = () => {
    return typeMap[trendType].percentagekey;
  }

  const getSupport = () => {
    return typeMap[trendType].supportKey;
  }

  const getResistance = () => {
    return typeMap[trendType].resistanceKey;
  }

  const handleGroupSelection = (value, flag) => {
    if (flag === "W") {
      setWatchlistSelected(value);
      setIndexSelected("");
    } else {
      setWatchlistSelected("");
      setIndexSelected(value);
    }
  }

  const getTrends = () => {
    const req = {};
    if (indexSelected) {
      req.indexUId = indexSelected;
    } else if (watchlistSelected) {
      req.watchlistUId = watchlistSelected;
    }
    dispatch(fetchTrendtocks(req));
  }

  const getTrendIcon = (item, trendType) => {
    const trend = trendType === "D" ? item.day.trend : trendType === "W" ? item.week.trend : item.month.trend;

    return trend === "UP" ? <img src={upTrendIcon} alt="Uptrend" /> : <img src={downTrendIcon} alt="Downtrend" />;
  };

  const getTrendText = (item, trendType) => {
    const trend = trendType === "D" ? item.day.trend : trendType === "W" ? item.week.trend : item.month.trend;

    return trend === "UP" ? "In Uptrend" : "In Downtrend";
  };


  // --------------------------------------------------------

  const numOfTotalPages = Math.ceil(trend_stocks.length / trendCardsPerPage)
  let leftPageBound, rightPageBound;

  if (currentPage === numOfTotalPages) {
    leftPageBound = Math.max(numOfTotalPages - 2, 1);
    rightPageBound = numOfTotalPages;
  } else if (currentPage === 1) {
    leftPageBound = 1;
    rightPageBound = Math.min(numOfTotalPages, currentPage + 2);
  } else {
    leftPageBound = Math.max(currentPage - 1, 1);
    rightPageBound = Math.min(currentPage + 1, numOfTotalPages);
  }

  const pages = Array.from({ length: rightPageBound - leftPageBound + 1 }, (_, i) => leftPageBound + i);


  const indexOfLastCard = currentPage * trendCardsPerPage;
  const indexOfFirstCard = indexOfLastCard - trendCardsPerPage;

  const visibleCards = trend_stocks.slice(indexOfFirstCard, indexOfLastCard)

  const prevPageHandler = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1)
  }

  const nextPageHandler = () => {
    if (currentPage !== numOfTotalPages) setCurrentPage(currentPage + 1)
  }

  // --------------------------------------------------------

  return (
    <div className={`container ${styles.wrapper}`}>

      <div>
        {trend_stocks && (
          <>
            <div className={styles.filter_section}>
              <div className={styles.trends_dropdown_wrapper}>
                <select className={`${watchlistSelected === '' ? styles.grey_text : styles.black_text}`} value={watchlistSelected} onChange={(event) => handleGroupSelection(event.target.value, 'W')}>
                  <option disabled value=''>Select Watchlist</option>
                  {
                    watchlists.map((item, index) => {
                      return (
                        <option key={index} value={item.watchlistUId}>{item.watchlistName}</option>
                      )
                    })
                  }
                </select>
              </div>

              <div className={styles.filter_dashed_line}></div>

              <div className={styles.trends_dropdown_wrapper}>
                <select className={`${indexSelected === '' ? styles.grey_text : styles.black_text}`} value={indexSelected} onChange={(event) => handleGroupSelection(event.target.value, "I")}>
                  <option disabled value=''>Select Index</option>
                  {
                    indices.map((item, index) => {
                      return (
                        <option key={index} value={item.indexUId}>{item.indexName}</option>
                      )
                    })
                  }
                </select>
              </div>

              <div className={styles.filter_dashed_line}></div>

              <div className={styles.trends_btn_wrapper}>
                <input type="button" value='VIEW' onClick={getTrends} className={styles.trends_filter_btn} />
              </div>
            </div>


            <div className={styles.closing_style}>
              <span>Closing basis on : </span>
              <input type="button" value='DAILY' className={`${styles.closing_basis_btn} ${trendType === "D" ? styles.active : ""}`} onClick={() => setTrendType('D')}></input>
              <input type="button" value='WEEKLY' className={`${styles.closing_basis_btn} ${trendType === "W" ? styles.active : ""}`} onClick={() => setTrendType('W')}></input>
              <input type="button" value='MONTHLY' className={`${styles.closing_basis_btn} ${trendType === "M" ? styles.active : ""}`} onClick={() => setTrendType('M')}></input>
            </div>

            {/* <Table data={data} cellBordered={true} height={400}>
              <Column flexGrow={1}>
                <HeaderCell>No</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) => {
                    return <span>{rowIndex}</span>;
                  }}
                </Cell>
              </Column>
              <Column align="center" flexGrow={3}>
                <HeaderCell>STOCK</HeaderCell>
                <Cell dataKey="stock" />
              </Column>

              <Column flexGrow={2}>
                <HeaderCell>LTP</HeaderCell>
                <Cell dataKey="ltp" />
              </Column>

              <Column flexGrow={2}>
                <HeaderCell>TREND</HeaderCell>
                <Cell dataKey={getTrendType()} />
              </Column>

              <Column flexGrow={2}>
                <HeaderCell>% Diff</HeaderCell>
                <Cell dataKey={getPercentageDiff()} />
              </Column>

              <Column flexGrow={2}>
                <HeaderCell>RESISTANCE</HeaderCell>
                <Cell dataKey={getResistance()} />
              </Column>
              <Column flexGrow={2}>
                <HeaderCell>SUPPORT</HeaderCell>
                <Cell dataKey={getSupport()} />
              </Column>
            </Table> */}
            {/* <div style={{ padding: 20 }}>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                // boundaryLinks
                maxButtons={5}
                size="xs"
                layout={["-", "limit", "|", "pager"]}
                total={trend_stocks.length}
                limitOptions={[10, 30, 50]}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div> */}

            <div className={styles.trends_card_grid}>
              <div className="row">
                {visibleCards.map((item, index) => (
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className={styles.trends_card_wrapper}>
                      <div className={styles.trends_card_top}>
                        <h4>{item.stock}</h4>
                        <div className={styles.trends_logo_wrapper}>
                          <img src={kotakImage} alt="" />
                        </div>
                      </div>
                      <div className={styles.card_dashed_line}></div>
                      <div className={styles.trends_card_body}>
                        <div className={styles.card_body_desc}>
                          <h5>LTP :</h5>
                          <p>{item.ltp}</p>
                        </div>
                        <div className={styles.card_body_desc}>
                          <h5>Next Resistence :</h5>
                          <p>{trendType === "D" ? item.day.resistance : trendType === "W" ? item.week.resistance : item.month.resistance}</p>
                        </div>
                      </div>
                      <div className={styles.card_dashed_line}></div>
                      <div className={styles.trends_card_footer}>
                        <div className={styles.card_footer_trend}>
                          {getTrendIcon(item, trendType)}
                          <p>{getTrendText(item, trendType)}</p>
                        </div>
                        <img src={downArrow} alt="" />
                      </div>
                    </div>
                  </div>
                ))}

              </div>
            </div>

            {/* ========== pagination starts ============= */}
            <div className='pagination-container'>
              <div className='pagination-wrapper'>

                <button onClick={prevPageHandler} className={currentPage === 1 ? "disabled-button" : ""}><FiChevronsLeft /></button>
                <div className='pagination-item-wrapper'>
                  {pages.map((page) => (
                    <span
                      key={page}
                      onClick={() => setCurrentPage(page)}
                      className={`${currentPage === page ? "active-notifications" : "pagination-item"}`}
                    >{`${page}`}</span>
                  ))}
                </div>
                <button onClick={nextPageHandler} className={currentPage === numOfTotalPages ? "disabled-button" : ""}><FiChevronsRight /></button>

              </div>
            </div>
            {/* ========== pagination starts ============= */}
          </>
        )}
      </div>
    </div>
  )
}
