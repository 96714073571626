import React, { useState } from "react";
import Card from "./Card";
import './Grid.css';
import { FiChevronsRight } from "react-icons/fi";
import { FiChevronsLeft } from "react-icons/fi";

const Grid = (data) => {

  const [dipCardsPerPage, setDipCardsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);

  const sampleData = data.source ? data.source : [];

  const itemsPerPage = 12;
  const totalPages = Math.ceil(sampleData.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sampleData.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // --------------------------------------------------------

  const numOfTotalPages = Math.ceil(sampleData.length / dipCardsPerPage)
  let leftPageBound, rightPageBound;

  if (currentPage === numOfTotalPages) {
    leftPageBound = Math.max(numOfTotalPages - 2, 1);
    rightPageBound = numOfTotalPages;
  } else if (currentPage === 1) {
    leftPageBound = 1;
    rightPageBound = Math.min(numOfTotalPages, currentPage + 2);
  } else {
    leftPageBound = Math.max(currentPage - 1, 1);
    rightPageBound = Math.min(currentPage + 1, numOfTotalPages);
  }

  const pages = Array.from({ length: rightPageBound - leftPageBound + 1 }, (_, i) => leftPageBound + i);


  const indexOfLastCard = currentPage * dipCardsPerPage;
  const indexOfFirstCard = indexOfLastCard - dipCardsPerPage;

  const visibleNotifications = sampleData.slice(indexOfFirstCard, indexOfLastCard)

  const prevPageHandler = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1)
  }

  const nextPageHandler = () => {
    if (currentPage !== numOfTotalPages) setCurrentPage(currentPage + 1)
  }

  // --------------------------------------------------------

  return (
    <div className="thickwallet-grid-container">
      <div className="grid">
        <div className="row">
          {visibleNotifications.map((item, index) => (
            <Card key={index} data={item} />
          ))}
        </div>
      </div>

      {/* Pagination below */}
      {/* <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>{currentPage}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div> */}

      {/* ========== pagination starts ============= */}
      <div className='pagination-container'>
        <div className='pagination-wrapper'>

          <button onClick={prevPageHandler} className={currentPage === 1 ? "disabled-button" : ""}><FiChevronsLeft /></button>
          <div className='pagination-item-wrapper'>
            {pages.map((page) => (
              <span
                key={page}
                onClick={() => setCurrentPage(page)}
                className={`${currentPage === page ? "active-notifications" : "pagination-item"}`}
              >{`${page}`}</span>
            ))}
          </div>
          <button onClick={nextPageHandler} className={currentPage === numOfTotalPages ? "disabled-button" : ""}><FiChevronsRight /></button>

        </div>
      </div>
      {/* ========== pagination starts ============= */}

    </div>
  );
};

export default Grid;
