import React from 'react';
import styles from './Card.module.css'
import apLogoImage from '../../assets/image/dashboard-images/ap_logo.png'
import downTrendIcon from '../../assets/image/dashboard-images/down-arrow 1.png'
import downArrow from '../../assets/image/dashboard-images/image 8.png'

const Card = ({ data }) => {
  return (
    <div className='col-lg-3 col-md-4 col-sm-6'>
      {/* <p>{data.stock}  {data.threshold}</p> */}
      <div className={styles.dips_card_wrapper}>
        <div className={styles.dips_card_top}>
          <h4>{data.stock}</h4>
          <div className={styles.dips_logo_wrapper}>
            <img src={apLogoImage} alt="" />
          </div>
        </div>
        <div className={styles.card_dashed_line}></div>
        <div className={styles.dips_card_body}>
          <div className={styles.downtrend_card_desc}>
            <h4><img src={downTrendIcon} alt="" /> {data.threshold}%</h4>
            <h5>In 54 Days</h5>
          </div>
          <div className={styles.downtrend_card_desc}>
            <span></span>
            <p><span>LTP :</span> {data.cmp}</p>
          </div>
        </div>
        <div className={styles.card_dashed_line}></div>
        <div className={styles.dips_card_footer}>
          <div className={styles.card_footer_trend}>
            {/* <img src={downTrendIcon} alt="" /> */}
            <p>In Downtrend</p>
          </div>
          <img src={downArrow} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Card;
