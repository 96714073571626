import React from 'react'
import styles from './InsightsCard.module.css'
import apLogoImage from '../../assets/image/dashboard-images/ap_logo.png'
import downTrendIcon from '../../assets/image/dashboard-images/down-arrow 1.png'
import downArrow from '../../assets/image/dashboard-images/image 8.png'

const InsightsCard2 = () => {
    return (
        <div className={styles.insights_card_wrapper}>
            <div className={styles.insights_card_top}>
                <h4>Asian Paint</h4>
                <div className={styles.insights_logo_wrapper}>
                    <img src={apLogoImage} alt="" />
                </div>
            </div>
            <div className={styles.card_dashed_line}></div>
            <div className={styles.insights_card_body}>
                <div className={styles.downtrend_card_desc}>
                    <h4>5%</h4>
                    <h5>In 54 Days</h5>
                </div>
                <div className={styles.downtrend_card_desc}>
                    <span></span>
                    <p>1000.05</p>
                </div>
            </div>
            <div className={styles.card_dashed_line}></div>
            <div className={styles.insights_card_footer}>
                <div className={styles.card_footer_trend}>
                    <img src={downTrendIcon} alt="" />
                    <p>In Downtrend</p>
                </div>
                <img src={downArrow} alt="" />
            </div>
        </div>
    )
}

export default InsightsCard2