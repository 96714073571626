import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useHistory, } from 'react-router-dom';
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";

import styles from './Home.module.css';
import Header from '../../components/header/Header';
import { Watchlist } from '../watchlist/Watchlist';
import { Dashboard } from '../dashboard/Dashboard';
import { Dip } from '../dip/Dip';
import { Trend } from '../trend/Trend';
import { selectUser } from '../login/LoginSlice';
import BreadCrumbs from '../../components/breadcrumbs/BreadCrumbs';

const Home = () => {
  const history = useHistory();
  const userObject = useSelector(selectUser);
  const [renderDashboard, setRenderDashboard] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);

  const [showSidebar, setShowSidebar] = useState(false);

  //Validate session.
  useEffect(() => {
    if (!(userObject?.isAuthenticated)) history.push('/landing-page');
  }, [])

  /**
   * Function to hide the Dashboard and navigate to an individual feature component.
   * Called when a feature tile is clicked.
   * @param {number} index 
   */
  const goToIndividualFeature = (index) => {
    // console.log(index);
    setRenderDashboard(false);
    setActiveIndex(index);
    switch (index) {
      case 0: history.push('/home/dip');
        break;
      case 1: history.push('/home/trend');
        break;

      default: history.push('/home/dip');
        break;
    }

  }

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const closeSidebar = () => {
    setShowSidebar(false);
  };

  return (
    <div className='container-fluid'>
      <div className={`row ${styles.wrapper}`}>
        {/* <Header /> */}

        <div className='col-12'>
          <div className='row'>
            <div className={`col-lg-2 col-md-3 col-sm-12 ${styles.sidebar_wrapper}`}>
              <Watchlist />
            </div>

            <div className={`${styles.dashboard_sidebar} ${showSidebar ? styles.active : ''}`}>
              <div className={styles.close_section}>
                <span></span>
                <button onClick={closeSidebar}><IoClose /></button>
              </div>
              <div className={styles.dashboard_sidebar_options}>
                <Watchlist />
              </div>
            </div>

            <div className='col-lg-10 col-md-9 col-sm-12'>
              <Header toggleSidebar={toggleSidebar} showSidebar={showSidebar} />
              {
                renderDashboard ? <Dashboard navigationCallback={goToIndividualFeature} /> :
                  <>
                    <div className='container' >
                      {/* <input type='button' className='btn btn-success bi bi-arrow-return-left' value='Back To Dashboard' onClick={() => { setRenderDashboard(true); history.push('/home'); }}></input> */}
                      <BreadCrumbs
                        activeIndex={activeIndex}
                        onBackToDashboard={() => { setRenderDashboard(true); history.push('/home'); }}
                        breadcrumbNavigation={goToIndividualFeature}
                      />
                    </div>
                    <Router>
                      <Switch>
                        <Route path='/home/dip' component={Dip} exact></Route>
                        <Route path='/home/trend' component={Trend} exact></Route>
                      </Switch>
                    </Router>
                  </>
              }


            </div>
          </div>
        </div>



      </div>
    </div>
  )
}



export default Home;
