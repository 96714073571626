import React from 'react'
import styles from './BreadCrumb.module.css';
import dashboardIcon from '../../assets/image/dashboard-images/home 1.png'
import dipsIcon from '../../assets/image/dashboard-images/dipsIcon.png'
import trendsIcon from '../../assets/image/dashboard-images/TrendsIcon.png'
import stocks360Icon from '../../assets/image/dashboard-images/global-economy 1.png'
import ETFIcon from '../../assets/image/dashboard-images/ETFIcon.png'
import FnOIcon from '../../assets/image/dashboard-images/chart 1.png'
import equitiesIcon from '../../assets/image/dashboard-images/private-equity 1.png'
import upstoxIcon from '../../assets/image/dashboard-images/ready-stock 1.png'
import bondsIcon from '../../assets/image/dashboard-images/money 1.png'

const BreadCrumbs = (props) => {

    const breadcrumbs_tiles = [
        { id: 0, icon: dipsIcon, title: "Dips" },
        { id: 1, icon: trendsIcon, title: "Trends" },
        { id: 2, icon: stocks360Icon, title: "Stocks 360" },
        { id: 3, icon: ETFIcon, title: "ETFs" },
        { id: 4, icon: FnOIcon, title: "F&O" },
        { id: 5, icon: equitiesIcon, title: "Equities" },
        { id: 6, icon: upstoxIcon, title: "Upstox" },
        { id: 7, icon: bondsIcon, title: "Bonds" },
    ];

    return (
        <div className={styles.breadcrumb_container}>
            <div className={styles.breadcrumb_page_btn} onClick={props.onBackToDashboard}>
                <div className={styles.dashboard_icon_container}>
                    <img src={dashboardIcon} alt="dash board" />
                </div>
                <span className={styles.breadcrumb_page_name}>Dashboard</span>
            </div>

            {breadcrumbs_tiles.map((tile, index) => (
                <div className={styles.breadcrumb_page_btn} key={index} onClick={() => props.breadcrumbNavigation(index)}>
                    <div className={`${styles.page_icon_container} ${props.activeIndex === tile.id ? styles.active_icon_container : '' }`}
                    >
                        <img src={tile.icon} alt="dips" />
                    </div>
                    <span className={styles.breadcrumb_page_name}>{tile.title}</span>
                </div>
            ))}
        </div>
    )
}

export default BreadCrumbs